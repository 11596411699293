import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';
import { Company } from '@/settings/domain/company/Company';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class CompanyServices implements CompanyRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN;
  private readonly API_COMPANY_FINDALL: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_FINDALL;
  private readonly API_COMPANY_SAVE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_SAVE;
  private readonly API_COMPANY_UPDATE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_UPDATE;
  private readonly API_COMPANY_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_FIND_BY_ID;

  async create(company: Company) {
    try {
      return await http.post<Company[]>(this.API_COMPANY_SAVE, JSON.stringify(company));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(company: Company) {
    try {
      return await http.put<Company[]>(this.API_COMPANY_UPDATE, JSON.stringify(company));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<Company[]> {
    try {
      return await http.get<Company[]>(this.API_COMPANY_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findCompanyById(companyId: string): Promise<Company[]> {
    try {
      return await http.get<Company[]>(this.API_COMPANY_FIND_BY_ID + companyId);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
