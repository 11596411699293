import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { showAlert } from '@/core/plugins/Notifications';
import i18n from '@/core/plugins/i18n';

Vue.use(VueRouter);

const isLogged = function(to: any, from: any, next: any) {
  const publicPages = ['/customer/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('accessTokenCompany');
  const dateExpiration: any = Number(localStorage.getItem('dateExpirationCompany'));
  const date: any = new Date().getTime();
  if ((authRequired && !loggedIn) || date > dateExpiration) {
    localStorage.removeItem('accessTokenCompany');
    localStorage.removeItem('userNameCompany');
    localStorage.removeItem('userEmailCompany');
    localStorage.removeItem('dateExpirationCompany');
    localStorage.removeItem('dataModuleCompany');
    localStorage.removeItem('userRol');
    localStorage.removeItem('logoUser');
    localStorage.removeItem('menuUser');
    localStorage.removeItem('businessId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyCode');
    showAlert('info', i18n.t('general.messageToken'));
    return next('/customer/login');
  }
  next();
};
const routes: Array<RouteConfig> = [
  { path: '', redirect: { name: 'Login' } },
  {
    path: '/customer',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c: any) => c('router-view')
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/general/infrastructure/login/Login.vue')
      },
      {
        path: 'registration',
        name: 'Registration',
        component: () => import('@/general/infrastructure/login/customerRegistration/CustomerRegistration.vue')
      },
      {
        path: 'forgetpassword',
        name: 'ForgetPassword',
        component: () => import('@/general/infrastructure/login/ForgetPassword.vue')
      },
      {
        path: 'validatecompany',
        name: 'ValidateCompany',
        component: () => import('@/general/infrastructure/login/validateCompany/ValidateCompany.vue')
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/general/infrastructure/shared/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'default',
        name: 'DashboardDefault',
        component: () => import('@/general/infrastructure/dashboard/DashboardDefault.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/courier',
    name: 'Courier',
    component: () => import('@/general/infrastructure/shared/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'quotation',
        name: 'CreateQuotation',
        component: () => import('@/courier/infrastructure/ui/components/quotation/CreateQuotationN.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'orderA',
        name: 'CreateOrderA',
        component: () => import('@/courier/infrastructure/ui/components/order/CreateOrder.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'order',
        name: 'CreateOrder',
        component: () => import('@/courier/infrastructure/ui/components/order/CreateOrderN/CourierOrderContainer.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'assistedorder',
        name: 'CreateAssistedOrder',
        component: () => import('@/courier/infrastructure/ui/components/order/CreateOrderN/CreateAssistedOrder.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'orderhistory',
        name: 'OrderHistory',
        component: () => import('@/courier/infrastructure/ui/components/order/OrderHistory.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  }
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    // console.log(to, from, savedPosition);
    return new Promise(resolve => {
      const position = (function() {
        if (savedPosition) {
          return savedPosition;
        } else {
          if (to.hash) {
            return {
              selector: to.hash
            };
          }
        }
      })();
      router.app.$root.$once('triggerScroll', () => {
        router.app.$nextTick(() => resolve(position));
      });
    });
  }
});
export default router;
