import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { File } from '@/settings/domain/file/File';
import { FileRepository } from '@/settings/domain/file/FileRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class FileServices implements FileRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_FILE_FINDALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_FILE_FINDALL;
  private readonly API_FILE_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_FILE_SAVE;

  async create(file: File) {
    try {
      return await http.post<File[]>(this.API_FILE_SAVE, JSON.stringify(file));
    } catch (error) {
      throw new Error(error);
    }
  }
  async findAll(name: string): Promise<File[]> {
    try {
      return await http.get<File[]>(this.API_FILE_FINDALL + name);
    } catch (err) {
      throw new Error(err);
    }
  }
}
