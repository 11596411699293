import { httpMethod } from '../exception/httpMethodsEnum';
import { errorMessage } from '../exception/NewNotificationException';

const header: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};
const get = async <T>(url: string) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'GET',
    headers: header
  });

  errorMessage(response, httpMethod.GET);

  return (await response.json()) as T;
};
const post = async <T>(url: string, body: any) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'POST',
    headers: header,
    body
  });

  errorMessage(response, httpMethod.POST, body);

  return (await response.json()) as T;
};
const put = async <T>(url: string, body: any) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'PUT',
    headers: header,
    body
  });

  errorMessage(response, httpMethod.PUT, body);

  return response;
};

const _delete = async <T>(url: string, body: any) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'DELETE',
    headers: header,
    body
  });
  errorMessage(response, httpMethod.DELETE, body);
  return (await response.json()) as T;
};

const _singleDelete = async <T>(url: string) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'DELETE',
    headers: header
  });
  errorMessage(response, httpMethod.DELETE);
  return (await response.json()) as T;
};

export const http = {
  get,
  post,
  put,
  delete: _delete,
  singleDelete: _singleDelete
};
