import Vue from 'vue';
import Vuei18n from 'vue-i18n';
Vue.use(Vuei18n);
//Extract filenames of our json file locate in the folder "Locate"
function loadLocaleMessages() {
  const locales = require.context('@/core/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: any = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
//The function try to match  the full name of the langague. If that's unavailable
//match just the first two  letters
function checkDefaultLanguage() {
  let matched = null;
  const languages = Object.getOwnPropertyNames(loadLocaleMessages());
  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}
export const selectedLocale = checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'es';
export const languages = Object.getOwnPropertyNames(loadLocaleMessages());
export default new Vuei18n({
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages: loadLocaleMessages()
});
